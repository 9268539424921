















































































































































































































































import {
  defineComponent,
  useRouter,
  useContext,
  ref,
  watch,
  onMounted,
  computed,
  nextTick,
} from '@nuxtjs/composition-api';
import {
  AisInstantSearch,
  AisConfigure,
  AisHits,
  AisSearchBox,
  AisStats,
  AisPagination,
  AisPanel,
  AisStateResults,
  AisIndex,
} from 'vue-instantsearch';
import algoliasearchHelper from 'algoliasearch-helper';
import { useImage } from '~/composables';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { useUiState } from '~/composables';
import { debounce } from 'lodash-es';
import { truncateString } from '~/helpers/truncateString';
import useAlgolia from '~/modules/algolia/composables/useAlgolia';
import aa from 'search-insights';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import { isClient } from '~/rockitUI/components/utilities/helpers';

export default defineComponent({
  name: 'InstantSearch',
  directives: { clickOutside },
  components: {
    AisInstantSearch,
    AisHits,
    AisSearchBox,
    AisStats,
    AisPagination,
    AisPanel,
    AisConfigure,
    AisStateResults,
    AisIndex,
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { isSearchOpen, toggleSearch } = useUiState();

    const {
      algoliaClient,
      productIndex,
      categoriesIndex,
      userToken,
      transformCategoryItems,
      transformPageItems,
      transformProductItems,
    } = useAlgolia();

    const searchClient = {
      ...algoliaClient.value,
      search(requests) {
        if (requests.every(({ params }) => !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
              hitsPerPage: 0,
              exhaustiveNbHits: false,
              query: '',
              params: '',
            })),
          });
        }
        return algoliaClient.value.search(requests);
      },
    };

    const helper = algoliasearchHelper(algoliaClient, productIndex.value);

    const searchBox = ref(null);
    const searchInput = ref(null);
    const searchTerm = ref(helper.state.query);
    const searchPlaceholder = computed(() => {
      if (app.$device.isMobileOrTablet) {
        return app.i18n.t('Search here...');
      } else {
        return app.i18n.t('Search the entire store...');
      }
    });

    const searchFunction = (helper) => {
      searchTerm.value = helper.state.query;

      helper.search('query', {
        clickAnalytics: true,
        // enablePersonalization: true,
        // userToken: userToken.value,
      });
    };

    // reduce number of calls to the server
    const debouncedHandleSearch = debounce(searchFunction, 50);

    const resetSearch = () => {
      // workaround to clear search when clicking results
      // https://github.com/algolia/vue-instantsearch/issues/703
      // @ts-ignore
      // searchBox.reset();
      helper.setState({
        query: '',
      });
      searchInput.value.value = '';
      hideSearch();
    };

    const focusSearch = () => {
      if (searchTerm.value.length > 0) {
        toggleSearch(true);
        if (app.$device.isMobile) {
          window.scrollTo(0, 0);
        }
      }
    };

    const hideSearch = () => {
      if (isSearchOpen.value) {
        toggleSearch(false);
      }
    };

    const goToResults = async () => {
      if (searchInput.value.value < 1) {
        return;
      }
      await router.push(app.localePath(`/search?query=${searchTerm.value}`));
      resetSearch();
      hideSearch();
    };

    watch(searchTerm, (newVal) => {
      if (newVal && newVal.length > 0) {
        toggleSearch(true);
      } else {
        toggleSearch(false);
      }
    });

    watch(
      () => isSearchOpen.value,
      (value) => {
        if (!isClient) return;
        if (value) {
          nextTick(() => {
            document.body.classList.add(
              'max-lg:fixed',
              'max-lg:overflow-hidden',
              'max-lg:w-screen',
              'max-lg:h-screen',
              'max-lg:touch-none'
            );
          });
        } else {
          document.body.classList.remove(
            'max-lg:fixed',
            'max-lg:overflow-hidden',
            'max-lg:w-screen',
            'max-lg:h-screen',
            'max-lg:touch-none'
          );
        }
      },
      { immediate: true }
    );

    const {
      getMagentoImage,
      imageSizes: { searchOverlay: imageSize },
    } = useImage();

    const insightsMiddleware = createInsightsMiddleware({
      insightsClient: aa,
      insightsInitParams: {
        useCookie: true,
      },
    });

    const middlewares = ref([insightsMiddleware]);

    onMounted(async () => {
      aa('setUserToken', userToken.value);
    });

    return {
      searchClient,
      searchFunction,
      helper,
      searchTerm,
      searchBox,
      searchInput,
      imageSize,
      getMagentoImage,
      resetSearch,
      goToResults,
      focusSearch,
      hideSearch,
      productIndex,
      categoriesIndex,
      isSearchOpen,
      debouncedHandleSearch,
      truncateString,
      transformCategoryItems,
      transformPageItems,
      transformProductItems,
      searchPlaceholder,
      middlewares,
    };
  },
});
