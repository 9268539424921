//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkLoader',
  props: {
    loading: {
      default: true,
      type: Boolean,
    },
    size: {
      type: String,
      default: 'md',
      validator(value) {
        const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
        return sizes.includes(value.toLowerCase());
      },
    },
    color: {
      type: String,
      default: 'primary-light',
      validator(value) {
        const sizes = ['primary-light', 'primary-dark', 'primary-base', 'white', 'black'];
        return sizes.includes(value.toLowerCase());
      },
    },
  },
  setup() {
    const loaderSize = (size) => {
      switch (size) {
        case 'xs':
          return 'w-3 h-3';
        case 'sm':
          return 'w-5 h-5';
        case 'md':
          return 'w-8 h-8';
        case 'lg':
          return 'w-12 h-12';
        case 'xl':
          return 'w-14 h-14';
        default:
          return 'w-8 h-8';
      }
    };
    const loaderColor = (color) => {
      switch (color) {
        case 'primary-light':
          return 'border-primary-light';
        case 'primary-dark':
          return 'border-primary-dark';
        case 'primary-base':
          return 'border-primary-base';
        case 'white':
          return 'border-white';
        case 'black':
          return 'border-black';
        default:
          return 'border-primary-light';
      }
    };
    return { loaderSize, loaderColor };
  },
});
