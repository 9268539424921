var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-instant-search',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideSearch),expression:"hideSearch"}],staticClass:"relative flex h-full w-full items-center",attrs:{"index-name":_vm.productIndex,"search-client":_vm.searchClient,"search-function":_vm.searchFunction,"middlewares":_vm.middlewares,"insights":true}},[_c('ais-configure',{attrs:{"clickAnalytics":"true"}}),_vm._v(" "),_c('ais-search-box',{ref:"searchBox",attrs:{"show-loading-indicator":"","class-names":{
      'ais-SearchBox': 'w-full',
      'ais-SearchBox-input':
        'w-full h-7  rounded-full border-divider-base py-1 px-2 pr-7 text-base placeholder-gray-dark shadow-sm focus:outline-none lg:py-2 lg:px-4 lg:pr-8 lg:h-8',
      'ais-SearchBox-submit': 'absolute right-2 top-1/2 -translate-y-1/2',
      'ais-SearchBox-reset': 'absolute right-8 top-1/2 -translate-y-1/2',
      'ais-SearchBox-loadingIndicator': 'absolute right-2 top-1/2 -translate-y-1/2',
    }},on:{"focus":_vm.focusSearch,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.hideSearch.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.resetSearch.apply(null, arguments)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.goToResults.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var currentRefinement = ref.currentRefinement;
    var isSearchStalled = ref.isSearchStalled;
    var refine = ref.refine;
return [_c('input',{ref:"searchInput",staticClass:"h-7 w-full rounded-full border-divider-base px-2 py-1 pr-7 text-base placeholder-gray-dark shadow-sm focus:outline-none lg:h-8 lg:px-3 lg:py-2 lg:pr-8",attrs:{"type":"search","placeholder":_vm.searchPlaceholder},on:{"input":function($event){return refine($event.currentTarget.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.goToResults.apply(null, arguments)},"focus":_vm.focusSearch}}),_vm._v(" "),_c('div',{staticClass:"absolute right-2 top-1/2 -translate-y-1/2",attrs:{"hidden":!isSearchStalled}},[_c('RkLoader',{attrs:{"size":"sm"}}),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('Loading')))])],1),_vm._v(" "),_c('div',{staticClass:"absolute right-2 top-1/2 -translate-y-1/2",attrs:{"hidden":isSearchStalled}},[_c('RkButton',{staticClass:"flex items-center text-sm font-bold",on:{"click":_vm.goToResults}},[_c('RkIcon',{staticClass:"transition-all",attrs:{"id":"search-icon","icon":"magnify","size":"xs","aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('Search')))])],1)],1),_vm._v(" "),(currentRefinement && _vm.isSearchOpen)?_c('div',{staticClass:"absolute right-8 top-1/2 flex -translate-y-1/2 items-center"},[_c('RkButton',{on:{"click":function($event){refine('');
            _vm.searchInput.value = '';}}},[_c('RkIcon',{staticClass:"relative h-5 w-5",attrs:{"id":"cancel-search-icon","icon":"cross","size":"xs","aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('Close Search')))])],1)],1):_vm._e()]}},{key:"submit-icon",fn:function(){return [_c('RkButton',{staticClass:"flex items-center text-sm font-bold",on:{"click":function($event){_vm.goToResults;}}},[_c('RkIcon',{staticClass:"transition-all",attrs:{"icon":"magnify","size":"xs","aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('Search')))])],1)]},proxy:true}])}),_vm._v(" "),_c('transition',{attrs:{"name":"rk-opacity-fade"}},[(_vm.isSearchOpen)?_c('ais-panel',{attrs:{"id":'search-results-panel',"class-names":{
        'ais-Panel':
          'overscroll-none w-screen h-[calc(100vh-85px)] top-[85px] pb-[85px] z-30 fixed left-0 lg:absolute lg:pb-0 lg:bg-white lg:left-auto lg:right-0 lg:top-full lg:w-[900px] md:h-[450px] border-t shadow-lg overflow-auto md:overflow-hidden z-50',
        'ais-Panel-body':
          'grid grid-cols-1 md:grid-cols-8 gap-8 lg:h-full w-full bg-white p-3 md:p-6',
      }}},[_c('div',{staticClass:"order-2 h-full md:order-1 md:col-span-2 md:overflow-y-auto"},[_c('ais-index',{staticClass:"mb-8",attrs:{"index-name":_vm.categoriesIndex}},[_c('ais-stats',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var nbHits = ref.nbHits;
return [(nbHits)?_c('h3',{staticClass:"mb-2 font-bold"},[_vm._v(_vm._s(_vm.$t('Categories')))]):_c('div')]}}],null,false,3434371925)}),_vm._v(" "),_c('ais-hits',{attrs:{"transform-items":_vm.transformCategoryItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var items = ref.items;
return [_c('ul',{staticClass:"space-y-2"},_vm._l((items),function(item){return _c('li',{key:item.objectID,staticClass:"leading-tight"},[_c('nuxt-link',{attrs:{"to":_vm.localePath(item.url)},nativeOn:{"click":function($event){return _vm.resetSearch.apply(null, arguments)}}},[_vm._v(_vm._s(item.path))])],1)}),0)]}}],null,false,3582289481)}),_vm._v(" "),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hits = ref.results.hits;
return [_c('p',{directives:[{name:"show",rawName:"v-show",value:(!hits.length),expression:"!hits.length"}]},[_vm._v(_vm._s(_vm.$t('No results found')))])]}}],null,false,4263112053)})],1)],1),_vm._v(" "),_c('div',{staticClass:"order-1 flex h-full flex-col md:order-2 md:col-span-6"},[_c('ais-configure',{attrs:{"hitsPerPage":6}}),_vm._v(" "),_c('ais-hits',{attrs:{"transform-items":_vm.transformProductItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var items = ref.items;
      var sendEvent = ref.sendEvent;
return [_c('ul',{staticClass:"grid gap-4 md:grid-cols-2"},_vm._l((items),function(item){return _c('li',{key:item.objectID,staticClass:"col-span-1"},[_c('nuxt-link',{attrs:{"to":_vm.localePath(item.url) + '?queryId=' + item.__queryID},nativeOn:{"click":function($event){_vm.resetSearch();
                    sendEvent('click', item, 'Item Viewed');}}},[_c('div',{staticClass:"flex"},[_c('RkImage',{staticClass:"flex-shrink-0",attrs:{"src":_vm.getMagentoImage(item.image_url),"alt":item.name,"width":_vm.imageSize.width,"height":_vm.imageSize.height,"sizes":("sm:" + (_vm.imageSize.width) + " md:" + (_vm.imageSize.width * 2)),"format":"webp"}}),_vm._v(" "),_c('div',{staticClass:"pl-4"},[_c('h3',{staticClass:"mb-1 leading-tight"},[_vm._v(_vm._s(_vm.truncateString(item.name, 40)))]),_vm._v(" "),_c('span',{staticClass:"block font-bold"},[_vm._v(_vm._s(_vm.$fc(item.price)))])])],1)])],1)}),0)]}}],null,false,2645552682)}),_vm._v(" "),_c('div',{staticClass:"mt-4 flex flex-col items-center justify-between border-t border-divider-base pt-4 md:mt-auto md:flex-row"},[_c('ais-stats',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var nbHits = ref.nbHits;
return [_c('p',{staticClass:"mb-3 text-sm"},[_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(nbHits))]),_vm._v(" "+_vm._s(_vm.$t('Products found'))+"\n              ")])]}}],null,false,4260859861)}),_vm._v(" "),_c('ais-pagination',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var currentRefinement = ref.currentRefinement;
                    var pages = ref.pages;
                    var isFirstPage = ref.isFirstPage;
                    var isLastPage = ref.isLastPage;
                    var refine = ref.refine;
                    var createURL = ref.createURL;
return [_c('nav',{staticClass:"isolate inline-flex -space-x-px rounded-md shadow-sm",attrs:{"aria-label":"Pagination"}},[_c('a',{staticClass:"relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20",class:{ 'opacity-30': isFirstPage },attrs:{"href":createURL(currentRefinement - 1)},on:{"click":function($event){$event.preventDefault();return refine(currentRefinement - 1)}}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('Previous')))]),_vm._v(" "),_c('svg',{staticClass:"h-5 w-5",attrs:{"id":"search-previous-icon","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z","clip-rule":"evenodd"}})])]),_vm._v(" "),_vm._l((pages),function(page){return _c('a',{key:page,staticClass:"relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20",class:{
                    'z-20 border-primary-base bg-slate-50 font-bold text-primary-base':
                      page === currentRefinement,
                  },attrs:{"href":createURL(page)},on:{"click":function($event){$event.preventDefault();return refine(page)}}},[_vm._v("\n                  "+_vm._s(page + 1)+"\n                ")])}),_vm._v(" "),_c('a',{staticClass:"relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20",class:{ 'opacity-30': isLastPage },attrs:{"href":createURL(currentRefinement + 1)},on:{"click":function($event){$event.preventDefault();return refine(currentRefinement + 1)}}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('Next')))]),_vm._v(" "),_c('svg',{staticClass:"h-5 w-5",attrs:{"id":"search-next-icon","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z","clip-rule":"evenodd"}})])])],2)]}}],null,false,3944897837)})],1)],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }